<template>
  <div class="container-delete">
    <div class="main-title">
      <h1>Delete<span><i class="far fa-exclamation-circle" style="color: #e96464"></i></span></h1>
    </div>
    <div class="content-card-layout">
      <div class="main-content-card">
        <h4 class="ml-2 mb-10" style="font-weight: 400;color:red;">Do you want to delete ?</h4>
      </div>
    </div>
    <div class="section-footer-delete">
      <div class="btn-section">
        <v-btn class="btn-cancel" outlined @click="$emit('close')">
          cancel
        </v-btn>
        <v-btn class="btn-action cancel" @click="DeleteItem()" :loading="btnLoading">
          Delete
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    employeeRestDayId: {},
  },
  data() {
    return {
      employee_id: [],
      btnLoading: false,
    }
  },
  methods: {
    DeleteItem() {
      this.btnLoading = true;
      const id = this.$route.params.holiday_id;
      const result = this.employee_id.concat(this.employeeRestDayId)
      this.$axios.post(`company/rest-day/${id}/employees/remove`, {
        employee_id: result,
      }).then((res) => {
        if (res.data.code === 200) {
          this.btnLoading = false;
          setTimeout(() => {
            this.$emit('close');
            this.$emit('success');
            this.$notification.OpenNotification_DeleteItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.container-delete {
  width: 100%;
  height: 180px;

  .main-title h1 {
    font-size: 26px;
    font-weight: bold;
    margin-left: 30px;
  }
}
</style>
