<template>
  <v-dialog
      v-model="modalShowDescriptionHoliday"
      persistent
      width="600"
      @close="setModalShowDescriptionHoliday(false)"
      transition="scroll-x-transition">
    <v-card class="main-card-delete">
      <v-card-actions class="card-action-delete">
        <v-list-item-title class="title-header-form ml-5">
         <h1 class="text-color-detail"> Colors Detail</h1>
        </v-list-item-title>
        <v-btn
            icon
            depressed
            @click="setModalShowDescriptionHoliday(false)"
        >
          <v-icon class="icon-style">{{ icon }}</v-icon>
        </v-btn>
      </v-card-actions>

      <div class="show-detail-holiday">
        <div class="holiday-left">
          <div class="card-holiday-left weekly">
            <div class="circle-left">
              <i class="fal fa-calendar-alt"></i>
            </div>
            <p class="text-detail">ວັນພັກປະຈຳອາທິດ</p>
          </div>


          <div class="card-holiday-left yearly">
            <div class="circle-left">
              <i class="fal fa-calendar-alt"></i>
            </div>
            <p class="text-detail">ວັນພັກປະຈຳປີ</p>
          </div>
        </div>
        <div class="holiday-right">
          <div class="card-holiday-right monthly">
            <div class="circle-left">
              <i class="fal fa-calendar-alt"></i>
            </div>
            <p class="text-detail">ວັນພັກປະຈຳເດືອນ</p>
          </div>
          <div class="card-holiday-right specify">
            <div class="circle-left">
              <i class="fal fa-calendar-alt"></i>
            </div>
            <p class="text-detail">ວັນພັກຕາມກຳນົດ</p>
          </div>
        </div>
      </div>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    visible: {
      default: false,
    },
    icon: {
      default: 'mdi-close',
      type: String
    },
  },
  data() {
    return {
      modalShowDescriptionHoliday: false,

    }
  },

  methods: {
    setModalShowDescriptionHoliday(isVisible) {
      this.modalShowDescriptionHoliday = isVisible;
      this.$emit('change', isVisible);
    },
  },
  watch: {
    visible(val) {
      this.modalShowDescriptionHoliday = val;
    },
  }
}
</script>

<style scoped lang="scss">
.show-detail-holiday {
  width: 100%;
  height: 250px;
  display: flex;
  // background-color: yellow;


  .holiday-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;


    .card-holiday-left {
      width: 250px;
      height: 80px;
      margin: 5px 0;
      background-color: #6CB669;
      border-radius: 10px;
      position: relative;
    }

    .weekly {
      background-color: rgba(33, 140, 116, 0.8);
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 10px;
      position: relative;

      .text-detail{
        position: absolute;
        top: 25px;
        left: 80px;
        font-size: 22px;
        color: white;
        font-family: $font-family;
      }

      .circle-left {
        width: 50px;
        height: 50px;
        background-color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          font-size: 30px;
          color: rgba(33, 140, 116, 0.8);
        }

      }

    }

    .yearly {
      background-color: rgba(52, 172, 224, 1.0);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 10px;

      .text-detail{
        position: absolute;
        top: 25px;
        left: 80px;
        font-size: 22px;
        color: white;
        font-family: $font-family;
      }


      .circle-left {
        width: 50px;
        height: 50px;
        background-color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          font-size: 30px;
          color: rgba(52, 172, 224, 1.0);
        }
      }

    }

  }

  .holiday-right {
    // background-color: #719AFF;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: relative;

    .card-holiday-right {
      width: 250px;
      height: 80px;
      margin: 5px 0;
      // background-color: #6CB669;
      border-radius: 10px;
    }

    .monthly {
      background-color: rgba(255, 82, 82, 1.0);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 10px;


      .text-detail{
        position: absolute;
        top: 55px;
        left: 100px;
        font-size: 22px;
        color: white;
        font-family: $font-family;
      }


      .circle-left {
        width: 50px;
        height: 50px;
        background-color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          font-size: 30px;
          color: rgba(255, 82, 82, 1.0);
        }

      }
    }

    .specify {
      background-color: rgba(255, 177, 66, 1.0);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 10px;


      .text-detail{
        position: absolute;
        top: 165px;
        left: 100px;
        font-size: 22px;
        color: white;
        font-family: $font-family;
      }


      .circle-left {
        width: 50px;
        height: 50px;
        background-color: white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          font-size: 30px;
          color: rgba(255, 177, 66, 1.0);
        }

      }
    }
  }
}

.text-color-detail{
  font-size: 22px;
  font-family: $font-family;
}
</style>
