<template>
  <div class="mainWeekly">
    <div class="week-right">
      <v-form class="section-week-form" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field
                outlined
                dense
                :label="$t('restDay.name')"
                v-model="restDayData.name"
                :hint="`${server_errors.name}`"
                persistent-hint
            >

            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <div class="card-week-right">
        <v-row class="card-circle-weekly">
          <div class="circle-week" v-for="(item,index) in monthlyArray" :key="index" @click="changeMonthly(item)">
            <p :class="{'circle-active': activeIndex === item}">{{ item + 1 }}</p>
          </div>
        </v-row>
      </div>
      <div class="week-btn">
        <v-btn class="btn-save-change" :loading="btnLoading" @click="updated">
          {{ $t('restDay.edit') }}
        </v-btn>
        <v-btn outlined class="btn-cancel" @click="cancel()">
          {{ $t('restDay.cancel') }}
        </v-btn>
        <span @click="onDelete"><i class="fas fa-trash-alt"></i></span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Weekly",
  props: {
    activeTabs: {},
    valueEditRestDay: {}
  },
  data() {
    return {
      activeIndex: "",
      monthlyArray: [],
      restDayData: {},
      btnLoading: false,
      data: {
        name: "",
        value: "",
      },
      server_errors: {
        name: "",
        type: "",
        value: ""
      }
    }
  },

  methods: {
    changeMonthly(item) {
      this.activeIndex = item;
    },
    resetForm() {
      this.$refs.form.reset();
    },

    getRestDayMonthly() {
      const id = this.valueEditRestDay.id;
      this.$axios.get(`company/rest-day/${id}`).then((res) => {
        this.restDayData = res.data.data;
        this.activeIndex = res.data.data.value - 1;
      }).catch(() => {
      })
    },

    updated() {
      const id = this.valueEditRestDay.id;
      const data = {
        name: this.restDayData.name,
        value: this.activeIndex + 1,
        type: this.restDayData.type,
      }
      this.btnLoading = true;
      this.$axios.put(`company/rest-day/${id}`, data).then((res) => {
        if (res.data.code === 200) {
          setTimeout(() => {
            this.$emit('success');
            this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
          this.btnLoading = false;
          this.resetForm();
          this.$router.push({
            name: "restDay.index",
          }).catch(() => {

          })
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    },

    onDelete() {
      this.btnLoading = true;
      const id = this.valueEditRestDay.id;
      this.$axios.delete(`company/rest-day/${id}`).then((res) => {
        if (res.data.code === 200) {
          setTimeout(() => {
            this.$emit('success')
            this.$notification.OpenNotification_DeleteItem_OnSuccess('top-right', 'danger', 3000);
          }, 300);
          this.btnLoading = false;
          this.resetForm();
        }
      }).catch(() => {
      })
    },

    cancel() {
      setTimeout(() => {
        this.$emit('cancel')
      }, 300)
    }

  },

  watch: {
    valueEditRestDay: {
      deep: true,
      handler() {
        this.getRestDayMonthly();
      }
    },
  },


  created() {
    this.monthlyArray = Array.from(Array(31).keys());
    this.getRestDayMonthly();
  }
}
</script>

<style scoped lang="scss">
.mainWeekly {
  width: 100%;
  height: auto;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  .week-right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-direction: column;

    .section-week-form {
      width: 100%;
      height: auto;

    }

    .card-week-right {
      padding: 10px;
      width: 100%;
      height: auto;
      //  background-color: rgba(255, 255, 255,0.6);
      // background-color: #f6e58d;
      box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;

      .card-circle-weekly {
        width: 100%;
        height: 100%;
        // margin: 10px;
        margin: 10px 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 20px;
        cursor: pointer;

        .circle-week {
          width: 40px;
          height: 40px;
          background-color: white;
          border-radius: 50%;
          margin: 8px;
          text-align: center;
          padding-top: 8px;
          color: #6e6d6d;
          transition: ease 0.2s;
        }

        .circle-active {
          width: 40px;
          height: 40px;
          background-color: #18BBEC;
          border-radius: 50%;
          // margin: 5px;
          text-align: center;
          padding-top: 8px;
          color: white;
          margin: -8px 0;
        }
      }

      .circle-week:hover {
        width: 40px;
        height: 40px;
        background-color: #18BBEC;
        border-radius: 50%;
        margin: 5px;
        text-align: center;
        padding-top: 8px;
        color: white;
        transition: ease 0.1s;
      }
    }

    .week-btn {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
      position: relative;

      .btn-cancel {
        margin-left: 15px;
        margin-top: 5px;
        font-size: 18px;
        font-weight: 500 !important;
        font-family: $font-family;
        padding: 20px 15px;
      }

      span {
        cursor: pointer;
        position: absolute;
        right: 3px;
        font-size: 18px;
        color: rgba(225, 112, 85, 0.8);
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;

      }

      span :hover {
        background-color: #eef7ff;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
      }
    }
  }
}
</style>

