<template>
  <v-dialog
      v-model="modalEditRestDay"
      persistent
      fullscreen
      @close="setModalEditRestDay(false)"
      transition="slide-x-transition">
    <v-card class="main-card-delete">
      <v-card-actions class="card-action-delete">
        <v-list-item-title class="title-header-form ml-5">
          <i class="fal fa-calendar-alt"></i>
        </v-list-item-title>
        <v-btn
            icon
            depressed
            @click="setModalEditRestDay(false)"
        >
          <v-icon class="icon-style">{{ icon }}</v-icon>
        </v-btn>
      </v-card-actions>
      <v-divider></v-divider>
      <div class="from-content-rest-day-edit">
        <div class="section-title-name">
          <div class="section--content">
            <span style="margin-right: 20px"> <i class="fal fa-calendar-alt"></i></span>
            Update Holiday {{ valueEdit.type }}
            <span @click="disableEmployeeRestDay()" class="onPushEmployee"><i class="fal fa-user-plus"></i></span>
          </div>
        </div>
        <div class="section-holiday">
          <div v-if="valueEdit.type ==='weekly'">
            <template>
              <modalWeekly @success="onUpdateSuccess" @cancel="OnCancel" :valueEditRestDay="valueEdit"/>
            </template>
          </div>

          <div v-if="valueEdit.type ==='monthly'">
            <modalMonthly @success="onUpdateSuccess" @cancel="OnCancel" :valueEditRestDay="valueEdit"/>
          </div>
          <div v-if="valueEdit.type ==='yearly'">
            <modalYearly @success="onUpdateSuccess" @cancel="OnCancel" :valueEditRestDay="valueEdit"/>
          </div>
          <div v-if="valueEdit.type ==='specify'">
            <modalSpecify @success="onUpdateSuccess" @cancel="OnCancel" :valueEditRestDay="valueEdit"/>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import modalWeekly from "./RestDayTabs/modalEdit/modalWeekly";
import modalMonthly from "./RestDayTabs/modalEdit/modalMonthly";
import modalYearly from "./RestDayTabs/modalEdit/modalYearly";
import modalSpecify from "./RestDayTabs/modalEdit/modalSpecify";

export default {
  components: {
    modalWeekly,
    modalMonthly,
    modalYearly,
    modalSpecify
  },
  props: {
    valueEdit: {},
    visible: {
      default: false,
    },

    icon: {
      default: 'mdi-close',
      type: String
    },
  },
  data() {
    return {
      modalEditRestDay: false,
    }
  },

  methods: {
    setModalEditRestDay(isVisible) {
      this.modalEditRestDay = isVisible;
      this.$emit('change', isVisible);
    },

    onUpdateSuccess() {
      this.$emit('success')
      this.setModalEditRestDay(false)
    },

    OnCancel() {
      this.setModalEditRestDay(false)
    },

    disableEmployeeRestDay() {
      this.$router.push({
        name: "disableRestDayEmployee.index",
        params: {
          holiday_id: this.valueEdit.id
        }
      }).catch(() => {

      })
      this.setModalEditRestDay(false)
    }
  },


  watch: {
    visible(val) {
      this.modalEditRestDay = val;
    },
  }
}
</script>

<style scoped lang="scss">
.title-header-form {
  font-weight: 600;
  height: auto;
  text-transform: capitalize;
  font-size: 24px;
  letter-spacing: 1px;
  color: #4A4A4A;
}

.from-content-rest-day-edit {
  width: 100%;
  height: 100%;
  // background-color: red;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  .section-title-name {
    width: 600px;
    height: 80px;
    // background-color: red;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .section--content {
      font-size: 30px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: capitalize;
      font-weight: 600;
      letter-spacing: 1px;
      color: #4A4A4A;


      .onPushEmployee {
        right: 0;
        cursor: pointer;
        width: 40px;
        height: 40px;
        background-color: #18BBEC;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        margin-left: 20px;
        align-items: center;
        transition: 0.2s;

        i {
          color: white;
          font-size: 18px;
        }
      }

      .onPushEmployee:hover {
        transform: translateY(-8%);
      }
    }
  }

  .section-holiday {
    width: 900px;
    height: auto;
  }

}


</style>
