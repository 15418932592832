<template>
    <div>
        <v-dialog v-model="dialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <p class="text-h5 text-center" style="width: 100%;"><span class="lfont">ເພີ່ມພະນັກງານເຂົ້າເຮັດວຽກ <span
                                class="text-primary">{{ rest_day }}</span></span></p>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <div class="text-center">
                                    <a @click="downloadEmployee"><i class="fas fa-cloud-download"></i> Download
                                        Employee</a>
                                    <div class="choose-image">
                                        <div class="show-image">
                                            <i class="fas fa-cloud-upload-alt"></i>
                                            <input @change="PreviewFile" type="file" class="input-file-image"
                                                ref="fileInput" accept=".xlsx, .xls" />
                                        </div>
                                    </div>
                                    <span v-if="showFile" class="show-file-name">File Name:
                                        <span class="text-primary">{{ showFile }}</span></span><br />
                                    <v-btn color="blue-grey" class="ma-2 white--text" @click="importData()">
                                        Upload
                                        <v-icon right dark> mdi-cloud-upload </v-icon>
                                    </v-btn>
                                </div>

                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text class="btn-save" @click="close_modal">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        dialog: "",
        rest_day_id: "",
        rest_day: ""
    },
    data() {
        return {
            importFile: "",
            showFile: ""
        }
    },
    methods: {
        close_modal() {
            this.$emit('close', false);
        },
        PreviewFile: function (event) {
            const input = event.target;
            const file = input.files[0];
            const fileType = file["type"];
            const validImageTypes = [
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ];
            if (!validImageTypes.includes(fileType)) {
                this.$notification.ShowErrors(
                    "top-right",
                    "danger",
                    3000,
                    "image must be csv,xlsx"
                );
            } else if (input.files && input.files[0]) {
                this.importFile = input.files[0];
                this.showFile = this.importFile.name;
            }
        },
        clearFileInput() {
            this.showFile = '';
            this.$nextTick(() => {
                this.$refs.fileInput.value = '';
            });
        },
        importData() {
            let formData = new FormData();
            formData.append("file", this.importFile);
            formData.append("rest_day_id", this.rest_day_id);
            this.isLoading = true;
            this.$axios
                .post(`company/import/employee/to/rest-day`, formData)
                .then((res) => {
                    if (res.data.code === 200) {
                        this.clearFileInput();
                        this.server_errors = [];
                        this.$emit('success')
                        this.$emit('done')
                        this.$emit('close', false)
                    }
                })
                .catch((error) => {
                    this.clearFileInput();
                    this.isLoading = false;
                    const obj = error.response.data.errors;
                    for (let [key, value] of Object.entries(obj)) {
                        let text = value[0];
                        let cut = "";
                        for (var i = 0; i < text.length; i++) {
                            if (!isNaN(text.charAt(i))) {
                                if (text.charAt(i) != " ") {
                                    cut = text.charAt(i);
                                    break;
                                }
                            }
                        }
                        this.server_errors[key] = text.replace(`${cut}.`, "");
                    }
                });
        },
        downloadEmployee() {
            this.$emit('download')
            this.$axios
                .get(`company/export/employee/to/add/rest-day`, {
                    responseType: "blob",
                })
                .then((res) => {
                    this.$emit('success')
                    const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const fileLink = document.createElement("a");
                    fileLink.href = fileUrl;
                    fileLink.setAttribute("download", "import employee to rest day.xlsx");
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    document.body.removeChild(fileLink);
                })
                .catch((error) => {
                    this.isLoading = false;
                });
        }
    },

}
</script>

<style lang="scss" scoped>
.btn-save {
    color: white !important;
}
</style>