<template>
  <div class="mainWeekly">
    <div class="week-right">
      <v-form class="section-week-form" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field
                outlined
                dense
                :label="$t('restDay.name')"
                v-model="restDayData.name"
                :hint="`${server_errors.name}`"
                persistent-hint
            >

            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <div class="card-week-right">
        <v-row justify="center">
          <v-date-picker
              width="100%"
              color="orange lighten-2"
              v-model="picker"
              elevation="5"
              :show-current="false"
          ></v-date-picker>
        </v-row>
      </div>
      <div class="week-btn">
        <v-btn class="btn-save-change" :loading="btnLoading" @click="updated">
          {{ $t('restDay.edit') }}
        </v-btn>
        <v-btn outlined class="btn-cancel" @click="onCancel()">
          {{ $t('restDay.cancel') }}
        </v-btn>
        <span @click="onDelete"><i class="fas fa-trash-alt"></i></span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment"

export default {
  name: "Weekly",
  props: {
    activeTabs: {},
    valueEditRestDay: {},
  },
  data() {
    return {
      landscape: true,
      picker: new Date().toISOString().substr(0, 10),
      btnLoading: false,
      restDayData: {},

      data: {
        name: "",
        value: "",
      },
      server_errors: {
        name: "",
        type: "",
        value: ""
      }
    }
  },
  methods: {
    resetForm() {
      this.$refs.form.reset();
    },
    getRestDaySpecify() {
      const id = this.valueEditRestDay.id;
      this.$axios.get(`company/rest-day/${id}`).then((res) => {
        this.restDayData = res.data.data;
        this.picker = res.data.data.value;
      })
    },
    updated() {
      const id = this.valueEditRestDay.id;
      const data = {
        name: this.restDayData.name,
        value: moment(this.picker).format('YYYY-MM-DD'),
        type: this.restDayData.type,
      }
      this.btnLoading = true;
      this.$axios.put(`company/rest-day/${id}`, data).then((res) => {
        if (res.data.code === 200) {
          setTimeout(() => {
            this.$emit('success')
            this.$notification.OpenNotification_EditItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
          this.btnLoading = false;
          this.resetForm();
          this.activeIndex = 0;
          this.$router.push({
            name: "restDay.index",
          }).catch(() => {

          })
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    },
    onDelete() {
      this.btnLoading = true;
      const id = this.valueEditRestDay.id;
      this.$axios.delete(`company/rest-day/${id}`).then((res) => {
        if (res.data.code === 200) {
          setTimeout(() => {
            this.$emit('success')
            this.$notification.OpenNotification_DeleteItem_OnSuccess('top-right', 'danger', 3000);
          }, 300);
          this.btnLoading = false;
          this.resetForm();
        }
      }).catch(() => {
      })
    },

    onCancel() {
      setTimeout(() => {
        this.$emit('cancel')
      }, 300)
    }
  },
  watch: {
    valueEditRestDay: {
      deep: true,
      handler() {
        this.getRestDaySpecify();
      }
    }
  },
  created() {
    this.getRestDaySpecify();
  }
}
</script>

<style scoped lang="scss">
.mainWeekly {
  width: 100%;
  height: auto;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  .week-right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-direction: column;

    .section-week-form {
      width: 100%;
      height: auto;

    }

    .card-week-right {
      padding: 20px 10px;
      width: 100%;
      height: auto;

    }

    .week-btn {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
      position: relative;

      .btn-cancel {
        margin-left: 15px;
        margin-top: 5px;
        font-size: 18px;
        font-weight: 500 !important;
        font-family: $font-family;
        padding: 20px 15px;
      }

      span {
        cursor: pointer;
        position: absolute;
        right: 5px;
        font-size: 18px;
        color: rgba(225, 112, 85, 0.8);
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;

      }

      span :hover {
        background-color: #eef7ff;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
      }
    }
  }
}
</style>
