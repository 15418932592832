<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1><span class="rollback" @click="rollback"><i class="fas fa-arrow-circle-left"></i></span>{{
          $t("empRestDay.title") }} <span class="text-primary">{{ restDay.name }}</span></h1>
      </div>
    </div>

    <div class="section-action">

      <div class="search-action">
        <v-row>
          <v-col cols="12" md="4" sm="4">
            <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense
              v-model="filter"
              prepend-inner-icon="mdi-magnify" :placeholder="$t('Search.search')" @keypress.enter="handleEventFilter">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="12" sm="12">
            <div class="btn-new-create-action">
              <v-btn class="btn-create" @click="onCreate">
                {{ $t('empRestDay.create') }}
              </v-btn>
              <v-btn class="btn-create btn-success ml-4" @click="dialog_import = true">
                <i class="fal fa-file-import" style="margin-right: 4px"></i> Import
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>


    </div>

    <div class="section-form-content mt-6">
      <div class="block-content">
        <table class="table table-view">
          <thead>
            <tr>
              <th class="text-center"></th>
              <th class="text-left">
                {{ $t('empRestDay.no') }}
              </th>
              <th class="text-left">
                {{ $t('empRestDay.img') }}
              </th>
              <th class="text-left">
                {{ $t('empRestDay.gender') }}
              </th>
              <th class="text-left">
                {{ $t('empRestDay.name') }}
              </th>
              <th class="text-left">
                {{ $t('empRestDay.surname') }}
              </th>
              <th class="text-left">
                {{ $t('empRestDay.email') }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="( item, idx ) in  listEmployeeRestDay " :key="idx">
              <td></td>
              <td>
                {{ (pagination.current_page * 10) - 10 + idx + 1 }}
              </td>
              <td>
                <div class="image-RestDay">
                  <img :src="item.urlProfile" alt="">
                </div>
              </td>

              <td>
                {{ item.gender }}
              </td>
              <td>
                {{ item.name }}
              </td>
              <td>
                {{ item.surname }}
              </td>
              <td>
                {{ item.joinUser.email }}
              </td>

              <td class="text-end">
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined v-bind="attrs" v-on="on">
                      <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title class="btn-delete" @click="onDeleted(item.id)">Delete</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset"
          @paginate="fetchEmployeeRestDay">
        </Pagination>
        <ModalDelete>
          <template v-slot="{ close }">
            <Delete :employeeRestDayId="employeeRestDayId" @close="close" @success="fetchEmployeeRestDay()" />
          </template>
        </ModalDelete>
        <Loading v-if="isLoading" />
      </div>
      <modalImportEmployeeOnRestDay :dialog="dialog_import" :rest_day_id="rest_day_id" :rest_day="restDay.name"
        @close="(val) => dialog_import = val" @download="isLoading = true" @success="isLoading = false"
        @done="fetchEmployeeRestDay" />
    </div>
  </div>
</template>

<script>
import Loading from "../../../components/Loading";
import Pagination from "../../../components/Paginate/Pagination";
import Delete from "../../../components/DisableRestDayEmployee/Delete";
import modalImportEmployeeOnRestDay from "./Modals/modalImportEmployeeOnRestDay.vue";

export default {
  components: {
    Loading,
    Pagination,
    Delete, modalImportEmployeeOnRestDay
  },

  data() {
    return {
      dialog_import: false,
      rest_day_id: "",
      offset: 10,
      filter: "",
      pagination: {},
      per_page: 10,

      listEmployeeRestDay: [],
      isLoading: false,
      employeeRestDayId: "",
      restDay: ""
    }
  },
  methods: {
    rollback() {
      this.$router.back();
    },
    onCreate() {
      this.$router.push({
        name: "disableRestDayEmployee.create"
      })
    },
    onDeleted(employeeRestDayId) {
      this.employeeRestDayId = employeeRestDayId
      this.$store.commit("modalDelete_State", true);
    },
    handleEventFilter() {
      this.fetchEmployeeRestDay();
    },
    fetchEmployeeRestDay() {
      this.isLoading = true;
      const id = this.$route.params.holiday_id;
      this.rest_day_id = this.$route.params.holiday_id;
      this.$axios.get(`company/rest-day/${id}/employees`, {
        params: {
          filter: this.filter,
          page: this.pagination.current_page,
          per_page: this.per_page,
        }
      }).then((res) => {
        if (res.data.code === 200) {
          this.isLoading = false;
          this.listEmployeeRestDay = res.data.data.employee.data;
          this.restDay = res.data.data;
          this.pagination = res.data.data.employee.pagination;
          if (!this.listEmployeeRestDay.length > 0) {
            this.pagination.current_page = this.pagination.current_page - 1;
          }
        }
      }).catch(() => {

      })
    }
  },

  created() {
    this.fetchEmployeeRestDay();
  }
}
</script>

<style scoped lang="scss">
.btn-success {
  background-color: green !important;
}

.image-RestDay {
  width: 55px;
  height: 55px;
  background-color: #e0eaf0;
  border-radius: 50%;
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
}
</style>
